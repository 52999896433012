import { Dispatch, SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { ExtraWorkAggreementApprovalStatus, ExtraWorkAgreementResponse, GetProjectResponse } from "api/generatedApi";
import { sortCompareNumber, sortCompareString } from "utils/compares";
import { useOrder } from "shared/table/use-order";
import { MobileExtraWorkAgreementRow } from "./mobile-row";
import { capitalizeString } from "utils/formats";
import { TableHeaderConfig } from "shared/table/table-header-type";
import { TableHeader } from "shared/table/table-header";
import { MenuItem, Select, TableCell, TableRow } from "@mui/material";
import { useExtraWorkAgreementFormatter } from "../hooks/format";

interface Props {
  project: GetProjectResponse;
  agreements: ExtraWorkAgreementResponse[];
  selectedStatus?: ExtraWorkAggreementApprovalStatus | '';
  setSelectedStatus: Dispatch<SetStateAction<ExtraWorkAggreementApprovalStatus | ''>>;
}

export const MobileProjectExtraWorkAgreements = (props: Props) => {
  type AgreementSortableId = "Chosen" | "Name" | "Payment";

  const { agreements, project, selectedStatus, setSelectedStatus } = props;
  const { t } = useTranslation();
  const { getStatusFormatted } = useExtraWorkAgreementFormatter();
  const { direction, orderBy, getLabelProps } = useOrder<AgreementSortableId>("Name");

  const sortedData = useMemo(() => {
    var sortedList = [...agreements].filter(x => selectedStatus === '' || x.approvalStatus === selectedStatus);

    switch (orderBy) {
      case "Name":
        sortedList = [...sortedList].sort((a, b) => sortCompareString(direction, a?.name, b?.name));
        break;
      case "Payment":
        sortedList = [...sortedList].sort((a, b) => sortCompareNumber(direction, a.paymentDkr, b.paymentDkr));
        break;
    }

    return sortedList;
  }, [agreements, orderBy, direction, selectedStatus]);

  const headerConfig: TableHeaderConfig<AgreementSortableId>[] = [
    {
      id: "Name",
      title: t("dashboard.favorits.table.info"),
      sortable: true,
      alignment: "left",
      testid: "dashboard-extra-work-agreements-header-text",
    },
    {
      id: "Payment",
      title: capitalizeString(t("common.currency")),
      sortable: true,
      alignment: "right",
      testid: "dashboard-extra-work-agreements-header-payment",
    },
  ];

  return (
    <Box sx={{ flex: 1, display: "flex", flexDirection: "column", pt: 4, pb: 12 }}>
      {<Select label="filterByStatus" value={selectedStatus} displayEmpty
        onChange={(e) => setSelectedStatus(e.target.value as ExtraWorkAggreementApprovalStatus | '')}>
        <MenuItem value={''}>{t("dashboard.extraWorkAgreements.filter.allStatuses")}</MenuItem>

        <MenuItem value={"UnderDevelopment"}>{getStatusFormatted("UnderDevelopment")}</MenuItem>
        <MenuItem value={"Approved"}>{getStatusFormatted("Approved")}</MenuItem>
        <MenuItem value={"Rejected"}>{getStatusFormatted("Rejected")}</MenuItem>
        <MenuItem value={"AwaitingApproval"}>{getStatusFormatted("AwaitingApproval")}</MenuItem>
        <MenuItem value={"Cancelled"}>{getStatusFormatted("Cancelled")}</MenuItem>
      </Select>}
      <TableContainer component={Paper}>
        <Table>
          <TableHeader headerConfig={headerConfig} getLabelProps={getLabelProps} />
          <TableBody>
            {sortedData.map((agreement) => {
              return <MobileExtraWorkAgreementRow key={agreement.extraWorkAgreementId} project={project} row={agreement} />;
            })}
            {sortedData.length === 0 && <TableRow>
              <TableCell colSpan={10}>{t("dashboard.extraWorkAgreements.table.tableEmpty")}</TableCell>
            </TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
