import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Table, TableCell, TableRow, Typography } from "@mui/material";
import { ImportReferenceResponse } from "api/generatedApi";
import { useTranslation } from "react-i18next";
import { FormDialogProps } from "shared/dialog/types";
import { ScreenSizeEnum, useScreenSize } from "shared/use-screen-size";
import { ImportReferenceApprovalData } from "../input/import-reference-approval-data";
import { useDownloadReport } from "./hooks/use-download-report";
import { formatDate, formatTimestampToDate } from "utils/formats";
import { Check, DoNotDisturb } from "@mui/icons-material";

interface Props extends FormDialogProps<ImportReferenceApprovalData> {
  importReference: ImportReferenceResponse
}

export const ViewImportReferenceDetailDialog = (props: Props) => {
  const { t } = useTranslation();
  const { screenSize } = useScreenSize();
  const { importReference, onSubmit } = props;
  const downloadReport = useDownloadReport()

  const onApprove = () => {
    const vals = {
      importReferenceId: importReference.importReferenceId,
      status: "Approved"
    } as ImportReferenceApprovalData;
    onSubmit(vals);
  }
  const onReject = () => {
    const vals = {
      importReferenceId: importReference.importReferenceId,
      status: "Rejected"
    } as ImportReferenceApprovalData;
    onSubmit(vals);
  }
  const onDownloadReport = () => {
    downloadReport(importReference.importReferenceId!);
  }

  return (<Dialog fullWidth
    maxWidth="sm"
    PaperProps={{
      sx: {
        height: screenSize === ScreenSizeEnum.Mobile ? "100%" : "auto", // mobile is ignored
        margin: screenSize === ScreenSizeEnum.Mobile ? "32px 7px" : "32px",
        width: screenSize === ScreenSizeEnum.Mobile ? "100%" : "calc(100´%-64px)",
      },
    }} open={props.isOpen}>
    <DialogTitle component="div" textAlign={"center"} p={0}>
      <Typography variant="h5" color="primary.dark">
        {t("admin.import.details.title")}
      </Typography>
      <IconButton onClick={props.onClose} sx={{ position: "absolute", top: 0, right: 0 }}>
        <CloseIcon />
      </IconButton>
      <Divider sx={{ marginTop: 2 }} />
    </DialogTitle>
    <DialogContent>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography>{t("admin.import.details.status")}</Typography>
          <Box textAlign={"right"}>
            <Typography>{importReference.status}</Typography>
            {importReference.status === "ReadyForApproval" && <Box sx={{ display: "flex", gap: 3 }}>
              <Button variant="contained" color="error" onClick={onReject} title={t("admin.import.details.rejectImport")}>
                <DoNotDisturb />
              </Button>
              <Button variant="contained" color="success" onClick={onApprove} title={t("admin.import.details.approveImport")}>
                <Check />
              </Button>
            </Box>
            }
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography>{t("admin.import.details.startedAt")}</Typography>
          <Typography>{formatDate(formatTimestampToDate(importReference.date!), true)}</Typography>
        </Box>
        {importReference.status === "ApprovedAndApplied" &&
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>{t("admin.import.details.importAppliedAt")}</Typography>
            <Typography>{formatDate(formatTimestampToDate(importReference.importAppliedAt!), true)}</Typography>
          </Box>
        }
        <Box>
          <Typography variant="h6">{t("admin.import.details.materialSummary")}</Typography>
          <Table>
            <TableRow>
              <TableCell>{t("admin.import.details.tableHeaders.changes")}</TableCell>
              <TableCell>{t("admin.import.details.tableHeaders.conflicts")}</TableCell>
              <TableCell>{t("admin.import.details.tableHeaders.new")}</TableCell>
              <TableCell>{t("admin.import.details.tableHeaders.removed")}</TableCell>
              <TableCell>{t("admin.import.details.tableHeaders.unchanged")}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{importReference.materialSummary?.changes}</TableCell>
              <TableCell>{importReference.materialSummary?.conflicts}</TableCell>
              <TableCell>{importReference.materialSummary?.new}</TableCell>
              <TableCell>{importReference.materialSummary?.removed}</TableCell>
              <TableCell>{importReference.materialSummary?.unchanged}</TableCell>
            </TableRow>
          </Table>
        </Box>
        <Box>
          <Typography variant="h6">{t("admin.import.details.operationSummary")}</Typography>
          <Table>
            <TableRow>
              <TableCell>{t("admin.import.details.tableHeaders.changes")}</TableCell>
              <TableCell>{t("admin.import.details.tableHeaders.conflicts")}</TableCell>
              <TableCell>{t("admin.import.details.tableHeaders.new")}</TableCell>
              <TableCell>{t("admin.import.details.tableHeaders.removed")}</TableCell>
              <TableCell>{t("admin.import.details.tableHeaders.unchanged")}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{importReference.operationSummary?.changes}</TableCell>
              <TableCell>{importReference.operationSummary?.conflicts}</TableCell>
              <TableCell>{importReference.operationSummary?.new}</TableCell>
              <TableCell>{importReference.operationSummary?.removed}</TableCell>
              <TableCell>{importReference.operationSummary?.unchanged}</TableCell>
            </TableRow>
          </Table>
        </Box>
      </Box>

    </DialogContent>
    <DialogActions>

      <Button variant="contained" color="primary" onClick={onDownloadReport}>
        {t("admin.import.details.downloadReport")}
      </Button>
      <Button variant="outlined" onClick={props.onClose}>
        {t("common.close")}
      </Button>
    </DialogActions>
  </Dialog>);
}