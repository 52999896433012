import { Dispatch, SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { ExtraWorkAggreementApprovalStatus, ExtraWorkAgreementResponse, GetProjectResponse } from "api/generatedApi";
import { sortCompareNumber, sortCompareString, sortCompareTime } from "utils/compares";
import { useOrder } from "shared/table/use-order";
import { DesktopExtraWorkAgreementRow } from "./desktop-row";
import { useExtraWorkAgreementFormatter } from "../hooks/format";
import { capitalizeString } from "utils/formats";
import { TableHeaderConfig } from "shared/table/table-header-type";
import { TableHeader } from "shared/table/table-header";
import { MenuItem, Select, TableCell, TableRow, Typography } from "@mui/material";

interface Props {
  project: GetProjectResponse;
  agreements: ExtraWorkAgreementResponse[];
  selectedStatus?: ExtraWorkAggreementApprovalStatus | '';
  setSelectedStatus: Dispatch<SetStateAction<ExtraWorkAggreementApprovalStatus | ''>>;
}

export const DesktopProjectExtraWorkAgreements = (props: Props) => {
  type AgreementSortableId = "Chosen" | "Id" | "Name" | "Type" | "Hours" | "Payment" | "InternalId" | "Status";

  const { agreements, project, selectedStatus, setSelectedStatus } = props;
  const { t } = useTranslation();
  const { getTypeByAgreementFormatted, getStatusFormatted } = useExtraWorkAgreementFormatter();
  const { direction, orderBy, getLabelProps } = useOrder<AgreementSortableId>("InternalId", "desc");
  const sortedData = useMemo(() => {
    var sortedList = [...agreements].filter(x => selectedStatus === '' || x.approvalStatus === selectedStatus);

    switch (orderBy) {
      case "Id":
        sortedList = [...sortedList].sort((a, b) => sortCompareString(direction, a?.extraWorkAgreementNumber, b?.extraWorkAgreementNumber));
        break;
      case "InternalId":
        sortedList = [...sortedList].sort((a, b) => sortCompareNumber(direction, a?.extraWorkAgreementInternalNumber, b?.extraWorkAgreementInternalNumber));
        break;
      case "Name":
        sortedList = [...sortedList].sort((a, b) => sortCompareString(direction, a?.name, b?.name));
        break;
      case "Type":
        sortedList = [...sortedList].sort((a, b) => sortCompareString(direction, getTypeByAgreementFormatted(a), getTypeByAgreementFormatted(b)));
        break;
      case "Hours":
        sortedList = [...sortedList].sort((a, b) => sortCompareTime(direction, { hours: a.workTime?.hours, minutes: a.workTime?.minutes }, { hours: b.workTime?.hours, minutes: b.workTime?.minutes }));
        break;
      case "Payment":
        sortedList = [...sortedList].sort((a, b) => sortCompareNumber(direction, a.paymentDkr, b.paymentDkr));
        break;
    }

    return sortedList;
  }, [agreements, orderBy, direction, getTypeByAgreementFormatted, selectedStatus]);

  const headerConfig: TableHeaderConfig<AgreementSortableId>[] = [
    {
      id: "InternalId",
      title: t("dashboard.extraWorkAgreements.table.internalId"),
      sortable: true,
      alignment: "left",
      testid: "dashboard-extra-work-agreements-header-internalid",
    },
    {
      id: "Id",
      title: t("dashboard.extraWorkAgreements.table.id"),
      sortable: true,
      alignment: "left",
      testid: "dashboard-extra-work-agreements-header-id",
    },
    {
      id: "Name",
      title: t("common.name"),
      sortable: true,
      alignment: "left",
      testid: "dashboard-extra-work-agreements-header-name",
    },
    {
      id: "Type",
      title: t("dashboard.extraWorkAgreements.table.type"),
      sortable: true,
      alignment: "left",
      testid: "dashboard-extra-work-agreements-header-type",
    },
    {
      id: "Hours",
      title: t("common.time.hours"),
      sortable: true,
      alignment: "right",
      testid: "dashboard-extra-work-agreements-header-hours",
    },
    {
      id: "Payment",
      title: capitalizeString(t("common.currency")),
      sortable: true,
      alignment: "right",
      testid: "dashboard-extra-work-agreements-header-payment",
    },
    {
      id: "Status",
      title: t("dashboard.extraWorkAgreements.table.status"),
      sortable: true,
      alignment: "left",
      testid: "dashboard-extra-work-agreements-header-status",
    },
  ];

  return (
    <Box sx={{ flex: 1, overflowY: "hidden", display: "flex", flexDirection: "column", width: "100%", height: "100%", p: 4 }}>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Box sx={{
          display: "flex",
          alignItems: "center",
          p: 1,
          pb: 2,
          pr: 1,
          gap: 2
        }} >
          <Typography>{t("dashboard.extraWorkAgreements.filter.filterByStatus")} </Typography>
          <Select label="filterByStatus" value={selectedStatus} displayEmpty
            onChange={(e) => setSelectedStatus(e.target.value as ExtraWorkAggreementApprovalStatus | '')}>
            <MenuItem value={''}>{t("dashboard.extraWorkAgreements.filter.allStatuses")}</MenuItem>

            <MenuItem value={"UnderDevelopment"}>{getStatusFormatted("UnderDevelopment")}</MenuItem>
            <MenuItem value={"Approved"}>{getStatusFormatted("Approved")}</MenuItem>
            <MenuItem value={"Rejected"}>{getStatusFormatted("Rejected")}</MenuItem>
            <MenuItem value={"AwaitingApproval"}>{getStatusFormatted("AwaitingApproval")}</MenuItem>
            <MenuItem value={"Cancelled"}>{getStatusFormatted("Cancelled")}</MenuItem>
          </Select>
        </Box>
      </Box>
      <TableContainer sx={{ height: "calc(100vh - 240px)", overflowY: "auto", pb: "50px" }} component={Paper}>
        <Table stickyHeader>
          <TableHeader headerConfig={headerConfig} getLabelProps={getLabelProps} />
          <TableBody>
            {sortedData.map((agreement) => {
              return <DesktopExtraWorkAgreementRow key={agreement.extraWorkAgreementId} project={project} row={agreement} />;
            })}
            {sortedData.length === 0 && <TableRow>
              <TableCell colSpan={10}>{t("dashboard.extraWorkAgreements.table.tableEmpty")}</TableCell>
            </TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
