import { useTranslation } from "react-i18next";
import { Fragment } from "react";
import { UseFormRegister } from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { ExtraWorkAggreementApprovalStatus, ExtraWorkAgreementResponse, useGetApiProjectsByProjectIdExtraworkagreementsAndExtraWorkAgreementIdStatusTransitionsQuery } from "api/generatedApi";
import { InputContainer } from "shared/styles/input-container-style";
import { ExtraWorkAgreementFormData } from "../hooks/use-input-validation";
import {useOfflineStorage} from "../../../../../../../utils/offline-storage/use-offline-storage";

interface Props {
  agreement?: ExtraWorkAgreementResponse;
  register: UseFormRegister<ExtraWorkAgreementFormData>;
  disabled: boolean;
}

export const approvalStatusMap = new Map([
  ["UnderDevelopment", "dashboard.extraWorkAgreements.approvalStatuses.underDevelopment"],
  ["Approved", "dashboard.extraWorkAgreements.approvalStatuses.approved"],
  ["Rejected", "dashboard.extraWorkAgreements.approvalStatuses.rejected"],
  ["AwaitingApproval", "dashboard.extraWorkAgreements.approvalStatuses.awaitingApproval"],
  ["Cancelled", "dashboard.extraWorkAgreements.approvalStatuses.cancelled"]
]);

export const EditExtraWorkAgreementStatus = (props: Props) => {
  const { agreement, register, disabled } = props;
  const { t } = useTranslation();
  const { getCurrentProjectId, setCurrentProjectId } = useOfflineStorage();

  const {
    data: approvalStatuses,
    isLoading,
    isFetching,
  } = useGetApiProjectsByProjectIdExtraworkagreementsAndExtraWorkAgreementIdStatusTransitionsQuery(
    {
       extraWorkAgreementId: agreement?.extraWorkAgreementId as string,
       projectId: getCurrentProjectId()
    },
    { refetchOnMountOrArgChange: true }
  );

  return (
    <InputContainer sx={{ height: 100, maxWidth: 250 }}>
      <Fragment>
        <Typography variant="caption" color={"primary.main"}>
          {t("captions.approvalStatus")}
        </Typography>
        {disabled || agreement?.approvalStatus == 'Cancelled' ? (
          <Typography variant="body1">{t(approvalStatusMap.get(agreement?.approvalStatus ?? "") ?? "")}</Typography>
        ) : (
          <Select label="approvalStatus" defaultValue={agreement?.approvalStatus} {...register("approvalStatus")}>
            {!isLoading &&
              !isFetching &&
              approvalStatuses?.map((x) => {
                const translation = approvalStatusMap.get(x);
                if (translation != null) {
                  return (
                    <MenuItem key={x} value={x}>
                      {x && t(approvalStatusMap.get(x) ?? "")}
                    </MenuItem>
                  );
                }
              })}
          </Select>
        )}
      </Fragment>
    </InputContainer>
  );
};
